import React, { useState, useEffect } from 'react';
import fetchJsonp from 'fetch-jsonp';

import { useI18nContext } from '@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n';

require('es6-promise').polyfill();

const SmartFeedJobAlerts = (props) => {
  const {
    i18nState: { lang },
    t,
  } = useI18nContext();

  const [jaDetails, setJaDetails] = useState({
    CompanyGroupName: props.CompanyGroupName,
    EmailAddress: "",
    FirstName: "",
    LastName: "",
    keywords: "",
    geoLocation: "",
    Location: [],
    Region: [],
    Category: [],
    Industry: [],
    JobType: [],
    JobTime: [],
    MailFrequency: 86400000,
    acceptGDPR: false,
  });

  const groupOrIdParam = 'id';

  const [filterData, setFilterData] = useState({});
  const [locationData, setLocationData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [jobTypeData, setJobTypeData] = useState([]);
  const [jobTimeData, setJobTimeData] = useState([]);

  const [showGDPR, setShowGDPR] = useState(false);
  const [GDPRStatement, setGDPRStatement] = useState("");
  const [apiResponse, setApiResponse] = useState("NA");

  const [error, setError] = useState("");

  useEffect(() => {
    if (props.siteConfig.group) {
      if (props.location) {
        getApiData('location', 'GetLocationListByGroup', setLocationData);
      }
      if (props.region) {
        getApiData('region', 'GetRegionListByGroup', setRegionData);
      }
      if (props.category) {
        getApiData('category', 'GetCategoryListByGroup', setCategoryData);
      }
      if (props.industry) {
        getApiData('industry', 'GetIndustryListByGroup', setIndustryData);
      }
      if (props.jobType) {
        getApiData('JobType', 'GetJobTypeListByGroup', setJobTypeData);
      }
      if (props.jobTime) {
        getApiData('JobTime', 'GetJobTimes', setJobTimeData);
      }
    } else {
      if (props.location) {
        getApiData('location', 'GetAllLocationsListByCompany', setLocationData);
      }
      if (props.region) {
        getApiData('region', 'GetAllRegionsListByCompany', setRegionData);
      }
      if (props.category) {
        getApiData(
          'category',
          'GetAllCategoriesListByCompany',
          setCategoryData
        );
      }
      if (props.industry) {
        getApiData(
          'industry',
          'GetAllIndustriesListByCompany',
          setIndustryData
        );
      }
      if (props.jobType) {
        getApiData('JobType', 'GetJobTypes', setJobTypeData);
      }
      if (props.jobTime) {
        getApiData('JobTime', 'GetJobTimes', setJobTimeData);
      }
    }

    getGDPRApiData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const changeGDPRVal = () => {
    let temp = { ...jaDetails };

    temp.acceptGDPR = !temp.acceptGDPR;

    setJaDetails(temp);
  };

  const getApiData = (name, apiCall, setState) => {
       if (apiCall === "GetJobTimes") {
      const jsonList = [
        t("job-alerts.job-times.full-time"),
        t("job-alerts.job-times.part-time"),
        t("job-alerts.job-times.term-time"),
        t("job-alerts.job-times.variable-hours"),
        t("job-alerts.job-times.sessional"),
        t("job-alerts.job-times.fractional-hours"),
      ];

      const uniqueArray = [];

      uniqueArray.push(t("job-alerts.all"));

      jsonList.map((item, i) => {
        if (!uniqueArray.includes(item)) {
          uniqueArray.push(item);
        }
      });

      setState(uniqueArray);
    } else {
      fetchJsonp(
        props.siteConfig.sfAPIEndpoint +
          '/CareerPage/' +
          apiCall +
          '?' +
          groupOrIdParam +
          '=' +
          props.siteConfig.apiKey +
          '&region=All',
        {
          timeout: 15000,
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (jsonList) {
          let uniqueArray = [];

          uniqueArray.push(t("job-alerts.all"));

          jsonList.map((item, i) => {
            if (!uniqueArray.includes(item)) {
              uniqueArray.push(item);
            }
          });

          setState(uniqueArray);
        });
    }
  };

  const getGDPRApiData = (name, apiCall, setState) => {
    let gdprID = props.siteConfig.gdprKey || props.siteConfig.apiKey;

    if (props.siteConfig.group === true) {
      gdprID = props.siteConfig.gdprKey;
    }

    fetchJsonp(
      props.siteConfig.sfAPIEndpoint +
        '/CareerPage/GetGdprStatementByCompanyId?encodedCompanyId=' +
        gdprID,
      {
        timeout: 15000,
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (gdprStatement) {
        setGDPRStatement(gdprStatement);
      });
  };

  const subscribeToJobAlerts = () => {
    const serialize = function (obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
      return str.join('&');
    };

    let toPass = {
      [props.siteConfig.group ? 'GroupId' : 'CompanyId']:
        props.siteConfig.apiKey,
      TimeZoneDifferenceMinutes: 0,
      CompanyGroupName: props.siteConfig.companyName,
      MailFrequency: jaDetails.MailFrequency,
      EmailAddress: jaDetails.EmailAddress,
      FirstName: jaDetails.FirstName,
      LastName: jaDetails.LastName,
      ReturnToSearchURL: props.ReturnToSearchURL,
      VacancyPageBaseURL: props.VacancyPageBaseURL,
      OptInLink: props.OptInLink,
      UnsubscribeLink: props.UnsubscribeLink,
      PostingType: 'Careers',
      Location:
        jaDetails.Location[0] && jaDetails.Location[0] === 'All'
          ? ''
          : jaDetails.Location.join('|'),
      Region:
        jaDetails.Region[0] && jaDetails.Region[0] === 'All'
          ? ''
          : jaDetails.Region.join('|'),
      Industry:
        jaDetails.Industry[0] && jaDetails.Industry[0] === 'All'
          ? ''
          : jaDetails.Industry.join('|'),
      Category:
        jaDetails.Category[0] && jaDetails.Category[0] === 'All'
          ? ''
          : jaDetails.Category.join('|'),
      Distance: 15,
      Unit: 'Miles',
    };

    fetchJsonp(
      props.siteConfig.sfAPIEndpoint +
        '/CareerPage/SubscribeForJobAlert?id=' +
        props.siteConfig.apiKey +
        '&' +
        serialize(toPass),
      {
        timeout: 15000,
      }
    )
      .then(function (response) {
        
        return response.json();
      })
      .then(function (result) {
        setApiResponse(result);
      });
  };

  const updateField = (name, val) => {
    let temp = { ...jaDetails };

    temp[name] = val;

    setJaDetails(temp);
  };

  const addFilter = (label, item) => {
    let temp = { ...jaDetails };
    let templabel = label;
    if (templabel ==="Lleoliad")
    {
      templabel = "Location";
    }
    if (templabel ==="Categori")
    {
      templabel = "Category";
    }
    
    if (temp[templabel].includes(item)) {
      temp[templabel].splice(temp[templabel].indexOf(item), 1);
    } else {
      if (item === 'All') {
        temp[templabel] = ['All'];
      } else {
        temp[templabel].push(item);
      }
    }

    setJaDetails(temp);
  };

  const isItemChecked = (label, item) => {
    if (label === 'Lleoliad') {
      if (jaDetails['Location'].includes(item)) {
        return true;
      } else {
        return false;
      }
    } else if (label === 'Categori') {
      if (jaDetails['Category'].includes(item)) {
        return true;
      } else {
        return false;
      }
    } else 
    if (jaDetails[label].includes(item)) {
      return true;
    } else {
      return false;
    }
  };

  const renderApiData = (label, state) => {
    return (
      <div className="col-12 col-xs-12 col-md-6 ja-filters-check-wrap">
        <div className="ja-filters-check-inner-wrap">
          <div className="ja-filters-section-title">
            <label>{label}:</label>
          </div>
          {state &&
            Array.isArray(state) &&
            state.map((item, i) => (
              <div key={i} className="vf-alerts-check-wrap">
                <input
                  id={label + '_' + item}
                  checked={isItemChecked(label, item)}
                  onChange={() => addFilter(label, item)}
                  type="checkbox"
                  className="vf-alerts-check"
                  name={label}
                  value={item}
                  title={`Select ${label}`}
                  disabled={
                    jaDetails[
                      (label === 'Lleoliad' && 'Location') ||
                        (label === 'Categori' && 'Category') ||
                        (label === 'Location' && 'Location') ||
                        (label === 'Category' && 'Category')
                    ].includes('All') && item !== 'All'
                  }
                />
                <label
                  className="vf-alerts-check-label"
                  htmlFor={label + '_' + item}
                >
                  {' '}
                  {item}
                </label>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const validateFields = () => {
    return (
      jaDetails.acceptGDPR === true &&
      jaDetails.EmailAddress !== '' &&
      jaDetails.FirstName !== '' &&
      jaDetails.LastName !== ''
    );
  };

  return (
    <div className="">
      <form
        className=""
        onSubmit={(e) => {
          e.preventDefault();
          if (!validateFields()) {
            return setError(t('job-alerts.messages.complete-all-fields'));
          }
          subscribeToJobAlerts();
        }}
      >
        <div className="VF_Alerts_Wrap">
          <div className="VF_Alerts_Title">{t('job-alerts.sub-title')}</div>

          <div className="VF_Alerts_Inner_Wrap">
            <div className="row">
              <div className="col-12 col-md-4">
                <input
                  className="VF-form-control onchange-search form-control"
                  onChange={(e) => updateField('EmailAddress', e.target.value)}
                  placeholder={t('job-alerts.email-placeholder')}
                ></input>
              </div>
              <div className="col-12 col-md-4">
                <input
                  className="VF-form-control onchange-search form-control"
                  onChange={(e) => updateField('FirstName', e.target.value)}
                  placeholder={t('job-alerts.first-name')}
                ></input>
              </div>
              <div className="col-12 col-md-4">
                <input
                  className="VF-form-control onchange-search form-control"
                  onChange={(e) => updateField('LastName', e.target.value)}
                  placeholder={t('job-alerts.last-name')}
                ></input>
              </div>
            </div>
            <div className="row">
              {props.region && (
                <>
                  {renderApiData(
                    lang === 'en-GB' ? 'Location' : 'Lleoliad',
                    regionData
                  )}
                </>
              )}
              {props.category && (
                <>
                  {renderApiData(
                    lang === 'en-GB' ? 'Category' : 'Categori',
                    categoryData
                  )}
                </>
              )}
            </div>
            <div>
              <input
                type="checkbox"
                name="gdpr"
                value="acceptGDPR"
                checked={jaDetails.acceptGDPR}
                onChange={() => changeGDPRVal()}
              />
              <label style={{ marginLeft: '10px ' }} htmlFor="gdpr">
                {' '}
                {t('job-alerts.gdpr1')}{' '}
                <a
                  className="gdprStatementHover"
                  onClick={(e) => setShowGDPR(!showGDPR)}
                >
                  {t('job-alerts.gdpr2')}
                </a>{' '}
                {t('job-alerts.gdpr3')}
              </label>

              {showGDPR && (
                <div className="gdprStatement">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: GDPRStatement.Statement,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: GDPRStatement.GDPRCustomMessage,
                    }}
                  />
                </div>
              )}

              {GDPRStatement &&
              jaDetails.acceptGDPR === true &&
              jaDetails.EmailAddress !== '' &&
              jaDetails.FirstName !== '' &&
              jaDetails.LastName !== '' ? (
                <div className="subscrube-btn-wrap">
                  <button
                    className="btn btn-primary"
                    onClick={() => subscribeToJobAlerts()}
                  >
                    {t('job-alerts.subscribe')}
                  </button>
                </div>
              ) : (
                <div className="subscrube-btn-wrap">
                  <button
                    className="VF-btn ui secondary basic button fluid"
                    disabled
                  >
                    {t('job-alerts.details-to-subscribe')}
                  </button>
                </div>
              )}
            </div>
            {apiResponse === 'OK' && <>{t('job-alerts.messages.signed-up')}</>}
            {apiResponse === 'Error' && <>{t('job-alerts.messages.error')}</>}
            {apiResponse === 'Update' && (
              <>{t('job-alerts.messages.updated-subscription')}</>
            )}
            {apiResponse === 'Pending' && (
              <>{t('job-alerts.messages.signed-up-check-inbox')}</>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default SmartFeedJobAlerts;
